.scans-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
  border-radius: 10px;
  margin: auto;
}

.scans-title {
  font-size: 40px;
}

.scans-subtitle {
  font-size: 1.1em;
  color: #554D60;
  padding-bottom: 20px;
}

.title-black {
  color: black;
}

.title-purple {
  color: #7e3ba1;
}

.scans-list {
  width: 55%;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ------------------------ */
/* Desktop Layout – unchanged */
/* ------------------------ */
.desktop-scan-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px 16px;
  margin-bottom: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid #DBC3FF;
  transition: border-color 0.3s;
}

.desktop-scan-layout:hover {
  border-color: #0056b3;
}

.scan-profile {
  display: flex;
  align-items: center;
  gap: 12px;
}

.scan-image-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  border-radius: 10px;
}

.scan-image {
  width: auto;
  height: 100%;
  border-radius: 10px;
}

.scan-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.scan-date,
.scan-time {
  font-size: 14px;
  color: #888;
  margin: 2px 0;
}

.score-container {
  text-align: right;
}

.score-bar-container {
  position: relative;
  margin-top: 20px;
  width: 180%;
  margin-left: -80%;
}

.score-bar {
  position: relative;
  height: 10px;
  background: #e0e0e0;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
}

.score-bar-inner {
  height: 100%;
  background: #7e3ba1;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
}

.score-label-dynamic {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  background-color: white;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  color: #7e3ba1;
  font-weight: bold;
}

.scan-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chat-button {
  padding: 12px 24px;
  background: #7e3ba1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.chat-button:hover {
  background: #4521a3;
}

.scan-menu .menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  background: none;
  box-shadow: none;
  position: absolute;
  right: 0;
  top: 100%;
}

.dropdown-menu .dropdown-item {
  background: none;
  border: none;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  transition: background-color 0.3s ease;
}

.pagination button.active {
  background: #7e3ba1;
  color: #fff;
}

.pagination button:hover:not(.active) {
  background: #ccc;
}

.pagination button:disabled {
  background: #f0f0f0;
  cursor: not-allowed;
}

/* ------------------------ */
/* Mobile Layout – only on small screens */
/* ------------------------ */

/* Hide mobile layout by default */
.mobile-scan-layout {
display: none;
}

/* For screens up to 768px */
@media (max-width: 768px) {
.scans-list {
  width: 90%;
}
/* Hide desktop layout on small screens */
.desktop-scan-layout {
  display: none;
}
/* Show mobile layout */
.mobile-scan-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid #DBC3FF;
  margin-bottom: 10px;
}
/* Left column: image */
.mobile-scan-layout .mobile-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 8px;
}
.mobile-scan-layout .mobile-image img {
  width: auto;
  height: 100%;
  border-radius: 10px;
}
/* Middle column: scan title and score stacked vertically */
.mobile-scan-layout .mobile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.mobile-scan-layout .mobile-info .scan-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.mobile-scan-layout .mobile-info .mobile-score {
  margin: 0;
  font-size: 14px;
  color: #555;
}
/* Right column: actions */
.mobile-scan-layout .mobile-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mobile-scan-layout .chat-button {
  padding: 8px 12px;
  font-size: 12px;
}
}

/* For screens up to 480px, further adjustments */
@media (max-width: 480px) {
.scans-page {
  padding: 10px;
}
.scans-title {
  font-size: 24px;
}
.scans-subtitle {
  font-size: 0.8em;
}
.desktop-scan-layout,
.mobile-scan-layout {
  padding: 8px 12px;
  margin-bottom: 8px;
}
.scan-image-container {
  width: 60px;
  height: 60px;
}
.score-bar-container {
  margin-top: 10px;
}
.score-label-dynamic {
  font-size: 10px;
  top: -18px;
}
.chat-button {
  padding: 8px 12px;
  font-size: 10px;
}
.pagination button {
  font-size: 10px;
  padding: 3px 6px;
}
.scan-date,
.scan-time {
  font-size: 12px;
}
}
