

/* Overlay and Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Changed modal background to white to match the profile page */
.modal {
  background-color: #fff;
  padding: 30px 25px 20px 25px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-in-out;
}

/* Close Button */
.closeButton {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  color: #2c3e50;
  transition: color 0.2s;
}

.closeButton:hover {
  color: #7e3ba1;
}

/* Title */
.title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.8em;
  color: #2c3e50;
}

/* Search Box */
.searchBox {
  margin-bottom: 25px;
  width: 100%;
}

.searchBox input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

/* Hit List */
.hitsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hit {
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.hit:hover {
  background-color: #f0f0f0;
}

/* Product Image */
.productImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
  flex-shrink: 0;
}

/* Product Info */
.productInfo {
  flex: 1;
}

.productHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Product Title */
.productTitle {
  margin: 0 0 5px 0;
  font-size: 1.2em;
  color: #2c3e50;
  cursor: pointer;
  outline: none;
}

.productTitle:focus {
  box-shadow: 0 0 0 2px #7e3ba1;
}

/* Brand and Price */
.productBrand {
  margin: 0;
  color: #555;
}

.productPrice {
  margin: 0;
  color: #333;
}

/* Buttons */
.buyNowButton {
  display: inline-block;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: #ff9900;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.buyNowButton:hover {
  background-color: #e68a00;
}

/* Updated button colors to match profile page */
.addToCartButton {
  display: inline-block;
  margin-top: 8px;
  margin-left: 8px;
  padding: 8px 12px;
  background-color: #7e3ba1;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addToCartButton:hover {
  background-color: #6a2b86;
}

.addButton {
  background-color: #7e3ba1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addButton:hover {
  background-color: #6a2b86;
}

/* Ingredients Section */
.ingredients {
  margin-top: 10px;
}

.ingredientButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.ingredientButton {
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
  background-color: #6c757d;
}

.ingredientButton:hover {
  opacity: 0.8;
}

/* Safety Classes */
.safetySafe {
  background-color: #28a745;
}

.safetyLightgreen {
  background-color: #90ee90;
  color: black;
}

.safetyModerate {
  background-color: #ffc107;
  color: black;
}

.safetyWarning {
  background-color: #fd7e14;
}

.safetyUnsafe {
  background-color: #dc3545;
}

.safetyUnknown {
  background-color: #6c757d;
}

/* Ingredient Modal Styles */
.ingredientModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.ingredientContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Properties Container */
.propertiesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Property Bubbles */
.propertyBubble {
  padding: 4px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.9em;
  display: inline-block;
  white-space: nowrap;
}

/* Boolean Properties */
.booleanBubble {
  background-color: #4caf50;
}

/* Integer Properties */
.color0 {
  background-color: green;
}

.color1 {
  background-color: #9ACD32;
}

.color2 {
  background-color: yellow;
  color: black;
}

.color3 {
  background-color: orange;
}

.color4 {
  background-color: red;
}

.colorDefault {
  background-color: gray;
}

/* Key Container Styles */
.keyContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.keyItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
}

.safetyColorBox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

/* Close Modal Button */
.closeModalButton {
  padding: 10px 20px;
  background-color: #7e3ba1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closeModalButton:hover {
  background-color: #6a2b86;
}

/* No Results */
.noResults {
  text-align: center;
  color: #555;
  font-size: 1.1em;
  margin-top: 20px;
}

/* Pagination */
.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.pageButton {
  padding: 10px 15px;
  background-color: #7e3ba1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1em;
}

.pageButton:hover {
  background-color: #6a2b86;
}

.activePage {
  background-color: #6a2b86;
}

.navButton {
  padding: 10px 15px;
  background-color: #7e3ba1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2em;
}

.navButton:hover {
  background-color: #6a2b86;
}

.navButton.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Confirmation Modal Styles */
.confirmModal {
  background-color: #fff;
  padding: 30px 25px 20px 25px;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-in-out;
}

.confirmTitle {
  margin-top: 0;
  text-align: center;
  color: #2c3e50;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modalButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1em;
}

.confirmButton {
  background-color: #28a745;
  color: #fff;
}

.confirmButton:hover {
  background-color: #218838;
}

.cancelButton {
  background-color: #dc3545;
  color: #fff;
}

.cancelButton:hover {
  background-color: #c82333;
}

/* Result Modal Styles */
.resultModal {
  background-color: #fff;
  padding: 30px 25px 20px 25px;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-in-out;
  text-align: center;
}

.resultTitle {
  margin-top: 0;
  color: #2c3e50;
}

.closeResultButton {
  padding: 10px 20px;
  background-color: #7e3ba1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closeResultButton:hover {
  background-color: #6a2b86;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); }
  to { transform: translateY(0); }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .modal {
    padding: 20px 15px 15px 15px;
  }

  .productImage {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .productTitle {
    font-size: 1em;
  }

  .title {
    font-size: 1.5em;
  }

  .pageButton {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .navButton {
    padding: 8px 12px;
    font-size: 1em;
  }

  .confirmModal,
  .resultModal {
    padding: 20px 15px 15px 15px;
  }

  .modalButton {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .closeResultButton {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .buyNowButton, .addToCartButton {
    padding: 6px 10px;
    font-size: 0.9em;
  }
}
