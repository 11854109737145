/* Existing CSS remains unchanged ... */

/* Apply box-sizing to all elements for consistent sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 
  1) Main Container 
     Uses radial purple gradients.
*/
.profileContainer {
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
  min-height: 100vh;
  color: #2c3e50;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
}

.title-black {
  color: black;
}

.title-purple {
  color: #7e3ba1; 
}

/* Page description */
.pageDescription {
  text-align: center;
  color: #444;
  font-size: 1.1em;
  margin: 0 auto 30px auto;
  max-width: 800px;
  line-height: 1.4em;
}

/* User Details Header (Title + 3-Dot Menu) */
.userDetailsHeader {
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

/* The 3-dot button */
.moreOptions {
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  padding: 5px 10px;
}

.optionsContainer {
  position: relative; 
  display: inline-block;
  margin-left: 10px; 
}

.optionsMenu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  min-width: 140px;
  z-index: 9999;
}

.optionsMenu button {
  background: none;
  border: none;
  text-align: left;
  padding: 10px 16px;
  width: 100%;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s;
}

.optionsMenu button:hover {
  background-color: #f5f5f5;
}

.optionsMenu button:focus {
  outline: none;
  background-color: #eaeaea;
}

/* Header of the profile page */
.header {
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
}

/* Section within the profile page */
.section {
  margin-bottom: 40px;
}

/* Large Title at the top of the page */
.mainPageTitle {
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
}

/* Section Title */
.sectionHeader {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2c3e50;
  display: inline-block;
}

/* Container for section title and "Add Product" button */
.sectionHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Sign Out button */
.signOutButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s;
}

.signOutButton:hover {
  background-color: #c82333;
}

/* Container for horizontal scrolling */
.productScrollContainer {
  position: relative;
  overflow-x: auto;
  padding-bottom: 10px;
  height: 450px;
}

/* Inner container for products in horizontal layout */
.productScroll {
  display: flex;
  gap: 20px;
  padding-left: 40px;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

/* Individual product card */
.productCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  width: 250px;
  height: 400px;
  flex-shrink: 0;
  transition: box-shadow 0.3s, transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  perspective: 1000px;
  overflow: hidden;
}

.productCard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Product image */
.productImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Container for product information */
.productInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Product title */
.productTitle {
  margin: 0;
  font-size: 1.4em;
  color: #2c3e50;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

/* Expanded title */
.titleExpanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

/* Product brand */
.productBrand {
  margin: 0 0 10px 0;
  color: #555;
}

/* Text when no products are saved */
.noSavedProducts {
  color: #555;
  font-size: 1.1em;
  text-align: center;
}

/* "Add Products" button */
.addProductButton {
  background-color: #7e3ba1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.addProductButton:hover {
  background-color: #6a2b86;
}

/* User Credits Section */
.creditsSection {
  text-align: center;
  margin-bottom: 20px;
}

.creditsText {
  font-size: 1.2em;
  color: #2c3e50;
}

/* Remove Button */
.removeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(220, 53, 69, 0.8);
  border: none;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 3;
}

.removeButton:hover {
  background-color: rgba(167, 29, 42, 0.8);
}

/* Ingredient Modal Styles */
.ingredientModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease-in-out;
}

.ingredientContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

/* Confirmation Modal Styles */
.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2500;
  animation: fadeIn 0.3s ease-in-out;
}

.confirmContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

.confirmButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirmButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.confirmButton:hover {
  background-color: #a71d2a;
}

.cancelButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cancelButton:hover {
  background-color: #5a6268;
}

/* Full Title Overlay */
.fullTitleOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.95);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 3000;
}

.fullTitleOverlay p {
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 20px;
}

.closeTitleButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.closeTitleButton:hover {
  background-color: #a71d2a;
}

/* Container for properties within the ingredient modal */
.propertiesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Style for each property bubble */
.propertyBubble {
  padding: 4px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.9em;
  display: inline-block;
  white-space: nowrap;
}

/* Styles for boolean properties */
.booleanBubble {
  background-color: #4caf50;
}

/* Styles for integer properties based on value */
.color0 {
  background-color: green;
}

.color1 {
  background-color: #9ACD32;
}

.color2 {
  background-color: yellow;
  color: black;
}

.color3 {
  background-color: orange;
}

.color4 {
  background-color: red;
}

.colorDefault {
  background-color: gray;
}

/* Container for the safety key */
.keyContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

/* Individual key item */
.keyItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
}

/* Colored box representing safety levels */
.safetyColorBox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

/* Button to close the ingredient modal */
.closeModalButton {
  padding: 10px 20px;
  background-color: #0084ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closeModalButton:hover {
  background-color: #0056b3;
}

/* Styles for ingredient buttons within products */
.ingredientButton {
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
  background-color: #6c757d;
  word-break: break-word;
}

.ingredientButton:hover {
  opacity: 0.8;
}

/* Safety Classes for ingredient buttons */
.safetySafe {
  background-color: #28a745;
}

.safetyLightgreen {
  background-color: #90ee90;
  color: black;
}

.safetyModerate {
  background-color: #ffc107;
  color: black;
}

.safetyWarning {
  background-color: #fd7e14;
}

.safetyUnsafe {
  background-color: #dc3545;
}

.safetyUnknown {
  background-color: #6c757d;
}

/* Animations for modals */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* New Styles for Flipping Cards */
.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  height: 100%;
}

.flipped .cardInner {
  transform: rotateY(180deg);
}

.cardFront {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #f8f9fa;
  border-radius: 10px;
  transform: rotateY(180deg);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.backContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Full Product Title on the back */
.fullProductTitle {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #2c3e50;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ingredients Section on the back */
.ingredientsSection {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ingredients List */
.ingredientsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
  padding-right: 5px;
  overflow: auto;
  max-height: 150px;
}

/* Scrollbar styles for ingredientsList */
.ingredientsList::-webkit-scrollbar {
  width: 6px;
}

.ingredientsList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.ingredientsList::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.ingredientsList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ensure the card back and remove button layering */
.cardBack {
  z-index: 2;
}

.removeButton {
  z-index: 3;
}

/* Ensure product card has perspective */
.productCard {
  perspective: 1000px;
}

/* Prevent text selection during interactions */
.productCard,
.cardFront,
.cardBack {
  user-select: none;
}

/* Accessibility focus styles */
.productCard:focus .cardInner,
.productCard:focus .cardFront,
.productCard:focus .cardBack {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Ensure card front and back fill the entire card */
.cardFront,
.cardBack {
  width: 100%;
  height: 100%;
}

/* Responsive adjustments for card back */
@media (max-width: 600px) {
  .cardBack {
    padding: 10px;
  }

  .fullProductTitle {
    font-size: 1em;
    -webkit-line-clamp: 1;
  }

  .removeButton {
    width: 25px;
    height: 25px;
    font-size: 1em;
  }
}

/* Hide scrollbars for productScroll */
.productScroll::-webkit-scrollbar {
  height: 8px;
}

.productScroll::-webkit-scrollbar-track {
  background: transparent;
}

.productScroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.productScroll {
  -ms-overflow-style: none;
  scrollbar-width: thin;
}

.productScroll::-webkit-scrollbar {
  display: none;
}

/* Ensure ingredients container scrolls if needed */
.ingredients {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 8px;
}

.ingredients::-webkit-scrollbar {
  width: 6px;
}

.ingredients::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ingredients::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.ingredients::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Additional styling for ingredient buttons container */
.ingredientButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Ensure modal overlays have proper z-index */
.ingredientModal {
  z-index: 2000;
}

.confirmModal {
  z-index: 2500;
}

.fullTitleOverlay {
  z-index: 3000;
}

/* Adjust back content to fill card */
.backContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* "Buy More" Button */
.buyMoreButton {
  display: inline-block;
  margin-top: 15px;
  padding: 8px 12px;
  background-color: #ff9900;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;
  text-align: center;
}

.buyMoreButton:hover {
  background-color: #e68a00;
}

@media (max-width: 600px) {
  .buyMoreButton {
    padding: 6px 10px;
    font-size: 0.9em;
  }
}

/* User Details container */
.userDetailsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Profile Image */
.userProfileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

/* Placeholder if no image */
.placeholderImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

/* User Info (name, email, credits) */
.userInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* ------------------------------
   Edit Profile Modal Styles
------------------------------ */
.editProfileModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease-in-out;
}

.editProfileContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-in-out;
}

.profileForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profileInput {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.saveButton {
  background-color: #7e3ba1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.saveButton:hover {
  background-color: #6a2b86;
}

/* (Reusing .cancelButton defined earlier for modal cancel buttons) */

/* ---------------------------------------------------------
   TODO: Mobile Responsive Adjustments (only for mobile screens)
--------------------------------------------------------- */
@media (max-width: 600px) {
  /* Adjust container padding for mobile */
  .profileContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  /* Stack user details vertically on mobile */
  .userDetailsContainer {
    flex-direction: column;
    align-items: center;
  }
  
  /* Reduce main title font size */
  .mainPageTitle {
    font-size: 30px;
  }
  
  /* Reduce section header font size */
  .sectionHeader {
    font-size: 20px;
  }
  
  /* Adjust page description for mobile */
  .pageDescription {
    max-width: 90%;
    font-size: 1em;
  }
  
  /* Adjust product scroll container height */
  .productScrollContainer {
    height: 300px;
  }
  
  /* Adjust product card dimensions */
  .productCard {
    width: 200px;
    height: 350px;
  }
  
  /* Adjust product image height */
  .productImage {
    height: 120px;
  }
  
  /* Ensure section header container wraps */
  .sectionHeaderContainer {
    flex-wrap: wrap;
  }
}
