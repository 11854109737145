/*************************************************************
  1) Personalized Guide + Upload Section with Gradient Background
*************************************************************/
.unauthorized-upload-page__personalized-guide-upload-section {
  padding: 20px;
  position: relative;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
}

.unauthorized-upload-page__purple-gradient-section {
  background: transparent;
  color: #2c3e50;
  text-align: center;
  padding: 20px 0;
}

.unauthorized-upload-page__gradient-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.unauthorized-upload-page__gradient-subtitle {
  font-size: 1.2rem;
  max-width: 600px;
  margin: auto;
  margin-top: 30px;
  color: black;
}

.title-black {
  color: black;
}

.title-purple {
  color: #7e3ba1;
}

/*************************************************************
  2) Upload Page Container
*************************************************************/
.unauthorized-upload-page__upload-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #7e3ba1;
  border-radius: 10px;
  padding: 20px 40px 15px;
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: transparent;
}

/* When full scan is selected, allow the container to expand */
.unauthorized-upload-page__upload-page.full-scan {
  max-width: none;
  width: 100%;
}

.unauthorized-upload-page__upload-title {
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

.unauthorized-upload-page__upload-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* Force the two main panels to stay in one row */
.unauthorized-upload-page__form-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: nowrap;
}

/*************************************************************
  Left Panel: Scan Type & Gender
*************************************************************/
.unauthorized-upload-page__settings-left {
  flex: 1 1 300px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 400px;
}

.unauthorized-upload-page__scan-type-selector label,
.unauthorized-upload-page__gender-section label {
  color: #769192;
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}

/* Button groups (scan type, gender) */
.unauthorized-upload-page__button-group {
  display: flex;
  gap: 10px;
}

.unauthorized-upload-page__button-group button {
  padding: 10px 15px;
  border: 2px solid #999;
  background-color: transparent;
  color: #999;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  font-size: 1rem;
}

/* Active / hover states */
.unauthorized-upload-page__button-group button.active {
  border-color: #7e3ba1;
  color: #7e3ba1;
}
.unauthorized-upload-page__button-group button:hover {
  background-color: #f3e6ff;
  border-color: #7e3ba1;
  color: #7e3ba1;
}

/* Start Journey Button */
.unauthorized-upload-page__start-journey-button {
  background-color: #a16fb0;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: auto;
}
.unauthorized-upload-page__start-journey-button:hover {
  background-color: #8c4d8c;
}

/*************************************************************
  3) Right Panel: Upload Boxes
*************************************************************/
.unauthorized-upload-page__upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  flex: 2;
  max-width: 500px;
}

.unauthorized-upload-page__section-title {
  color: #769192;
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}

/* Ensure the upload boxes remain squares */
.unauthorized-upload-page__upload-box {
  width: 100%;
  max-width: 350px;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s;
}

/* Single upload (front or side only) */
.unauthorized-upload-page__upload-section--single-upload {
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.unauthorized-upload-page__upload-box--single-upload {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #e6d4f3; /* Light purple */
}

/* Double upload (front + side) */
.unauthorized-upload-page__double-upload-sections {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  justify-content: center;
  /* Ensure both placeholders remain at full size (350px each plus gap) */
  min-width: calc(350px * 2 + 20px);
}

/*************************************************************
  4) Placeholders & Images
*************************************************************/
.unauthorized-upload-page__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #bfa5e6;
  border-radius: 10px;
  box-sizing: border-box;
}

.unauthorized-upload-page__placeholder-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unauthorized-upload-page__uploaded-image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Hide default file input */
.unauthorized-upload-page__file-input {
  display: none;
}

/*************************************************************
  5) Loading Spinner
*************************************************************/
.unauthorized-upload-page__loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

/*************************************************************
  6) White "About Us" Section
*************************************************************/
.unauthorized-upload-page__white-about-section {
  background-color: #fff;
  padding: 60px 20px;
  text-align: center;
  color: #2c3e50;
}

.unauthorized-upload-page__white-about-section .unauthorized-upload-page__section-heading {
  font-size: 2rem;
  margin-bottom: 10px;
}

.unauthorized-upload-page__white-about-section
  .unauthorized-upload-page__section-heading
  .unauthorized-upload-page__highlight {
  color: #7e3ba1;
}

.unauthorized-upload-page__white-about-section .unauthorized-upload-page__section-subtitle {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #7e3ba1;
  font-weight: 500;
}

/* Two-column layout for About content */
.unauthorized-upload-page__about-content {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 40px;
  flex-wrap: nowrap;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.unauthorized-upload-page__about-text {
  flex: 1 1 400px;
  line-height: 1.6;
  max-width: 600px;
  margin: auto 0;
}

.unauthorized-upload-page__about-intro {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #769192;
}

.unauthorized-upload-page__about-bullets {
  list-style-type: disc;
  padding-left: 20px;
  color: #2c3e50;
  font-size: 1rem;
  line-height: 1.6;
}

.unauthorized-upload-page__about-image {
  flex: 1 1 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unauthorized-upload-page__about-image img {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/*************************************************************
  7) Light Purple "How It Works" Section
*************************************************************/
.unauthorized-upload-page__light-purple-howitworks-section {
  background-color: #fcf8ff;
  padding: 60px 20px;
  text-align: center;
}

.unauthorized-upload-page__light-purple-howitworks-section .unauthorized-upload-page__section-heading {
  font-size: 2rem;
  margin-bottom: 10px;
}
.unauthorized-upload-page__light-purple-howitworks-section
  .unauthorized-upload-page__section-heading
  .unauthorized-upload-page__highlight {
  color: #7e3ba1;
}

.unauthorized-upload-page__light-purple-howitworks-section .unauthorized-upload-page__section-subtitle {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #7e3ba1;
  font-weight: 500;
}

/* Steps */
.how-it-works-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.step-text {
  flex: 0 0 30%;
  max-width: 350px;
  padding: 0 20px;
}
.step-image {
  flex: 0 0 60%;
  max-width: 500px;
  padding: 0 20px;
  background: linear-gradient(to bottom right, #c89fd9, #f3e6ff);
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.step-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

/*************************************************************
  8) Crop Modal Styling
*************************************************************/
.crop-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.crop-modal {
  background: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.crop-modal h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #f3f3f3;
}

.crop-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.crop-modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.crop-modal-buttons button:first-child {
  background-color: #7e3ba1;
  color: #fff;
}

.crop-modal-buttons button:last-child {
  background-color: #ccc;
  color: #333;
}

/*************************************************************
  9) Responsive Adjustments for Screens Up to 1000px
*************************************************************/
@media (max-width: 1000px) {
  .unauthorized-upload-page__personalized-guide-upload-section,
  .unauthorized-upload-page__upload-page {
    width: 100%;
    padding: 40px;
  }

  /* Keep panels side by side on smaller screens */
  .unauthorized-upload-page__form-content-row {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .unauthorized-upload-page__settings-left,
  .unauthorized-upload-page__upload-section {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .unauthorized-upload-page__start-journey-button {
    width: 100%;
    margin-top: 20px;
  }

  .unauthorized-upload-page__double-upload-sections {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  /* About Us stacking */
  .unauthorized-upload-page__about-content {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .unauthorized-upload-page__about-text,
  .unauthorized-upload-page__about-image {
    flex: 1 1 auto;
    max-width: 100%;
  }
  .unauthorized-upload-page__about-image img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  /* How It Works stacking */
  .how-it-works-step {
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
  }
  .step-text,
  .step-image {
    flex: 1 1 auto;
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
}

/*************************************************************
  10) Extra Responsive Adjustments for Screens Up to 768px
*************************************************************/
@media (max-width: 768px) {
  /* Stack the left (settings) and right (upload) panels vertically */
  .unauthorized-upload-page__form-content-row {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  .unauthorized-upload-page__settings-left,
  .unauthorized-upload-page__upload-section {
    max-width: 100%;
    margin-bottom: 20px;
  }
  /* Allow double upload sections to wrap */
  .unauthorized-upload-page__double-upload-sections {
    flex-direction: column;
    min-width: unset;
  }
  /* Stack About Us content */
  .unauthorized-upload-page__about-content {
    flex-direction: column;
    text-align: center;
  }
  /* Stack How It Works steps */
  .how-it-works-step {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .step-text,
  .step-image {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
}

/*************************************************************
  11) Extra Responsive Adjustments for Screens Up to 480px
*************************************************************/
@media (max-width: 480px) {
  .unauthorized-upload-page__personalized-guide-upload-section,
  .unauthorized-upload-page__upload-page {
    padding: 15px;
  }
  
  .crop-modal {
    width: 95%;
    padding: 10px;
  }
  
  .crop-container {
    height: 250px;
  }

  .unauthorized-upload-page__gradient-title {
    font-size: 2rem;
  }

  .unauthorized-upload-page__gradient-subtitle {
    font-size: 1rem;
  }

  .unauthorized-upload-page__section-heading {
    font-size: 1.5rem;
  }

  .unauthorized-upload-page__section-subtitle {
    font-size: 1rem;
  }
}
