/* General Page Styling */
.unauthorized-routine-beauty-routine-page {
  padding: 20px 100px;
  min-height: 100vh;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
  color: #2c3e50; /* text color */
}

.unauthorized-routine-beauty-routine-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

/* Toggle Switch Container */
.unauthorized-routine-toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 10px 0;
}

/* Switch Styling */
.unauthorized-routine-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.unauthorized-routine-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider Styling */
.unauthorized-routine-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.unauthorized-routine-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checkbox Checked */
.unauthorized-routine-switch input:checked + .unauthorized-routine-slider {
  background-color: #7E3BA1;
}

.unauthorized-routine-switch input:checked + .unauthorized-routine-slider:before {
  transform: translateX(26px);
}

/* Toggle Label */
.unauthorized-routine-toggle-label {
  margin-left: 15px;
  font-size: 16px;
  color: #333;
}

/* Steps Grid Layout */
.unauthorized-routine-steps-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
  align-items: start;
}

@media (max-width: 800px) {
  .unauthorized-routine-steps-grid {
    grid-template-columns: 1fr;
  }
}

/* Step Card Styling */
.unauthorized-routine-step-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 20px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.unauthorized-routine-step-card.expanded {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.unauthorized-routine-step-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Step Header */
.unauthorized-routine-step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unauthorized-routine-step-header h2 {
  font-size: 24px;
  color: #2c3e50;
  margin: 0;
  flex-grow: 1;
}

.unauthorized-routine-priority-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7E3BA1;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 15px;
  flex-shrink: 0;
  font-size: 14px;
}

/* Expand Icon */
.unauthorized-routine-expand-icon {
  font-size: 24px;
  color: #7E3BA1;
  font-weight: bold;
}

/* Step Description */
.unauthorized-routine-step-card p {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

/* Step Content */
.unauthorized-routine-step-content {
  margin-top: 20px;
}

/* Routine Section */
.unauthorized-routine-routine-section {
  margin-top: 20px;
}

.unauthorized-routine-routine-section h3 {
  font-size: 18px;
  color: #34495e;
  margin-top: 10px;
}

.unauthorized-routine-routine-section p {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.unauthorized-routine-routine-steps {
  margin: 10px 0 10px 20px;
}

.unauthorized-routine-routine-steps li {
  margin-bottom: 5px;
  font-size: 14px;
  color: #444;
}

/* Routine Feedback */
.unauthorized-routine-routine-feedback {
  background-color: #f9f9f9;
  padding: 10px;
  border-left: 4px solid #7E3BA1;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

.unauthorized-routine-routine-feedback strong {
  color: #7E3BA1;
}

/* Product Recommendations Section */
.unauthorized-routine-product-recommendations {
  margin-top: 20px;
}

/* Dropdown Toggle Button */
.unauthorized-routine-product-recommendations-toggle {
  background-color: transparent;
  border: 2px solid #7E3BA1;
  color: #7E3BA1;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.unauthorized-routine-product-recommendations-toggle:hover {
  background-color: #7E3BA1;
  color: #fff;
  border-color: #7E3BA1;
}

.unauthorized-routine-product-recommendations-toggle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(103, 202, 199, 0.5);
}

/* Products Container within Dropdown */
.unauthorized-routine-products-container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Product Category Box */
.unauthorized-routine-product-category-box {
  flex: 1 1 30%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.unauthorized-routine-product-category-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Product Category Header */
.unauthorized-routine-product-category-header {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.unauthorized-routine-product-category-header.unauthorized-routine-beginner-box {
  background-color: #3498db;
}

.unauthorized-routine-product-category-header.unauthorized-routine-advanced-box {
  background-color: #e67e22;
}

.unauthorized-routine-product-category-header.unauthorized-routine-luxury-box {
  background-color: #9b59b6;
}

.unauthorized-routine-product-category-header.unauthorized-routine-general-box {
  background-color: #2ecc71;
}

/* Product Card */
.unauthorized-routine-product-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: inherit;
  text-decoration: none;
  padding: 15px;
  transition: background-color 0.3s ease;
  flex-grow: 1;
  justify-content: space-between;
  min-height: 450px;
  box-sizing: border-box;
  background-color: #fff;
}

.unauthorized-routine-product-card:hover {
  background-color: #f9f9f9;
}

/* Product Image */
.unauthorized-routine-product-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background-color: #ffffff;
  margin-bottom: 10px;
}

/* Product Name */
.unauthorized-routine-product-card h5 {
  font-size: 16px;
  color: #2c3e50;
  margin: 10px 0 5px 0;
}

/* Product Description */
.unauthorized-routine-product-card p {
  font-size: 14px;
  color: #777;
  flex-grow: 1;
  margin-bottom: 10px;
}

/* Buy Button */
.unauthorized-routine-buy-button {
  display: inline-block;
  margin: 10px 0 15px 0;
  padding: 8px 16px;
  background-color: #7E3BA1;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  transition: background-color 0.3s;
  align-self: center;
}

.unauthorized-routine-buy-button:hover {
  background-color: #5AB1AD;
}

/* Back Link */
.unauthorized-routine-back-link {
  margin-top: 40px;
  text-align: center;
}

.unauthorized-routine-back-button {
  color: #7E3BA1;
  text-decoration: none;
  font-size: 16px;
  padding: 8px 16px;
  border: 2px solid #7E3BA1;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.unauthorized-routine-back-button:hover {
  background-color: #7E3BA1;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .unauthorized-routine-product-category-box {
    flex: 1 1 45%;
  }
}

@media (max-width: 992px) {
  .unauthorized-routine-product-category-box {
    flex: 1 1 100%;
  }

  .unauthorized-routine-product-card h5 {
    font-size: 15px;
  }

  .unauthorized-routine-product-card p {
    font-size: 13px;
  }

  .unauthorized-routine-buy-button {
    padding: 7px 14px;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .unauthorized-routine-routine-section h3 {
    font-size: 16px;
  }

  .unauthorized-routine-routine-section p,
  .unauthorized-routine-routine-steps li,
  .unauthorized-routine-routine-feedback {
    font-size: 13px;
  }

  .unauthorized-routine-product-category-box {
    flex: 1 1 100%;
  }

  .unauthorized-routine-product-image {
    height: 150px;
  }
}

@media (max-width: 576px) {
  .unauthorized-routine-toggle-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .unauthorized-routine-toggle-label {
    margin-left: 0;
    margin-top: 10px;
    font-size: 14px;
  }

  .unauthorized-routine-step-header h2 {
    font-size: 20px;
  }

  .unauthorized-routine-step-card {
    padding: 15px;
  }

  .unauthorized-routine-routine-section h3 {
    font-size: 16px;
  }

  .unauthorized-routine-routine-section p,
  .unauthorized-routine-routine-steps li,
  .unauthorized-routine-routine-feedback {
    font-size: 12px;
  }

  .unauthorized-routine-product-card h5 {
    font-size: 14px;
  }

  .unauthorized-routine-product-card p {
    font-size: 12px;
  }

  .unauthorized-routine-buy-button {
    padding: 5px 10px;
    font-size: 13px;
  }

  /* New rule: Reduce side margins/padding for the main container on small screens */
  .unauthorized-routine-beauty-routine-page {
    padding: 10px 15px;
  }
}
