/* Global Box-Sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Profile Container with Gradient Background */
.profileContainer {
  padding: 20px 100px;
  min-height: 100vh;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
  color: #2c3e50;
}

/* Main Title and Description */
.mainPageTitle {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
}

.pageDescription {
  text-align: center;
  color: #444;
  font-size: 1.1em;
  margin: 0 auto 30px auto;
  max-width: 800px;
  line-height: 1.4em;
}

/* Explore Button */
.explore-button {
  padding: 15px 30px;
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.explore-button:hover {
  background-color: #ff4757;
}

/* Fixed Image Styling */
.fixedImage {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Canvas Limited Width */
.canvas-limited-width {
  width: 100%;
  height: auto;
}

/* Toggle Button Defaults */
.toggle {
  font-size: 1rem;
}

/* Desktop Layout for Results Section */
.resultsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 30px;
}

.resultsLeft {
  flex: 0 0 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  height: 1200px;
}

.resultsRight {
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  height: 500px;
}

/* New class for inner content of resultsRight */
.resultsRightContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}

/* New styles for header content and buttons */
.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.header-buttons {
  display: flex;
  gap: 20px;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .profileContainer {
    padding: 20px;
    min-width: auto !important;
    overflow-x: hidden;
  }
  
  /* Stack results vertically and reorder columns */
  .resultsWrapper {
    flex-direction: column;
    align-items: stretch;
    margin: 15px;
  }
  
  .resultsLeft,
  .resultsRight {
    flex: 1 1 100%;
    margin-right: 0;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Reorder so that the image (resultsRight) appears at the top */
  .resultsRight {
    order: -1;
  }
  
  /* Adjust the resultsRightContent to stack vertically so that the radar appears under the image */
  .resultsRightContent {
    flex-direction: column;
  }
  
  /* Adjust toggle font size */
  .toggle {
    font-size: 0.875rem;
  }
  
  /* Adjust header content for mobile: stack vertically */
  .header-content {
    flex-direction: column;
  }
  
  .header-buttons {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  /* Responsive adjustments for image and radar containers */
  .responsive-image-container,
  .responsive-radar-container {
    width: 90%;
  }
}

/* Extra Small Screens Adjustments */
@media (max-width: 480px) {
  .toggle {
    font-size: 0.75rem;
  }
}

/* Glow Up Card (if used) */
.glow-up-section {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.glow-up-card {
  width: 80%;
  max-width: 800px;
  padding: 40px;
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  text-align: center;
  transition: box-shadow 0.3s, transform 0.3s;
}

.glow-up-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.glow-up-card h2 {
  font-size: 2em;
  color: #333333;
  margin-bottom: 20px;
}

.glow-up-card p {
  font-size: 1.2em;
  color: #555555;
  margin-bottom: 30px;
}

/* Mobile Adjustments for Glow Up Card */
@media (max-width: 768px) {
  .glow-up-card {
    width: 90%;
    padding: 30px;
  }

  .glow-up-card h2 {
    font-size: 1.5em;
  }

  .glow-up-card p {
    font-size: 1em;
  }
}

/* Backdrop for Modals */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}
