/* -----------------------------------------------
   Reset and Basic Page Styles
----------------------------------------------- */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F8FAFD; /* Light background color that takes up the entire screen */
}

.chat-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh; /* Use min-height instead of fixed height */
  color: #2c3e50;
  background:
    radial-gradient(ellipse at 45% 0%, #f3e6ff 0%, #ffffff 50%),
    radial-gradient(ellipse at 135% 0%, #f3e6ff 0%, #ffffff 50%);
  background-position: left top, right top;
  background-size: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
}

/* Title Styles */
.upload-title {
  color: #2c3e50; /* Dark text color for readability */
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

/* -----------------------------------------------
   Container Layout: Two Columns
----------------------------------------------- */

.chat-container {
  display: flex;
  justify-content: center; 
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 20px; /* Space between left column & right column */
}

/* Left Column: Product Recommendations */
.left-column {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.product-recommendations-container {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 80vh;
  background-color: transparent;
}

.product-recommendations-container h3 {
  margin-bottom: 10px;
}

/* Right Column: Chat on Top, Routine Below */
.right-column {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between chat and routine */
}

/* -----------------------------------------------
   Chat Box (Right Column, Top)
----------------------------------------------- */
/* New Header Styles */
.chat-header {
  text-align: center;
  margin-bottom: 20px;
}

.chat-title {
  font-size: 32px; /* Larger title */
  margin: 0;
  color: #2c3e50;
}

.chat-subtitle {
  font-size: 16px; /* Smaller description */
  margin: 5px 0 0 0;
  color: #555;
}



.chat-messages-container {
  display: flex;
  flex-direction: column;
  height: 50vh; /* Adjust as you see fit */
}

/* Chat Messages */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #FFFFFF;
  border: 2px solid #7e3ba1; /* Primary border color */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Individual Messages */
.message {
  padding: 8px 12px;
  border-radius: 20px;
  margin-bottom: 8px;
  max-width: 80%;
  word-wrap: break-word;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

/* User & Assistant Message Colors */
.user-message {
  align-self: flex-end;
  background-color: #7e3ba1; /* Primary color */
  color: white;
  border-bottom-right-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.assistant-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #2c3e50;
  border-bottom-left-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Error Message in Chat */
.error-message {
  color: red;
  font-weight: bold;
  align-self: center;
  margin-top: 10px;
}

/* Chat Form */
.chat-form {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.chat-input:focus {
  border-color: #7e3ba1; /* Primary color on focus */
  outline: none;
}

/* Send Button */
.upload-button {
  padding: 10px 20px;
  background-color: #7e3ba1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.upload-button:disabled {
  background-color: #a0d9de;
  cursor: not-allowed;
}

.upload-button:hover:not(:disabled) {
  background-color: #8C4D8C;
}

/* -----------------------------------------------
   Current Beauty Routine (Right Column, Bottom)
----------------------------------------------- */

.current-beauty-routine-container {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  max-height: 30vh; /* Adjust as desired */
  overflow-y: auto;
  background-color: transparent;
}

.current-beauty-routine-container h3 {
  margin-bottom: 10px;
}

/* -----------------------------------------------
   Product & Routine Card Styles
----------------------------------------------- */

.product-scroll-container {
  position: relative;
  overflow-x: auto; /* Allow horizontal scrolling */
  padding-bottom: 10px;
}

.product-scroll {
  display: flex;
  gap: 10px;
  scroll-behavior: smooth;
  flex-wrap: nowrap; 
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 6px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  transition: box-shadow 0.3s, transform 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 120px; /* Keep consistent card size */
}

/* Hover Effect */
.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Product Card / Routine Card specific */
.product-card, .routine-card {
  width: 100px;
  min-height: 140px; /* Enough room for image & text */
}

.product-image, .routine-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 6px;
}

.product-info, .routine-info {
  text-align: center;
}

.product-title, .routine-title {
  font-size: 10px;
  margin: 0;
  color: #2c3e50;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* -----------------------------------------------
   Buttons
----------------------------------------------- */

/* End Session Button */
.end-session-button {
  padding: 10px 20px;
  background-color: #ff4d4f; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.end-session-button:hover {
  background-color: #ff7875; 
}

/* Load More */
.load-more-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #7e3ba1; 
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  display: block;
  width: 100%;
  text-align: center;
}

.load-more-button:hover {
  background-color: #8C4D8C;
  transform: translateY(-2px);
}

.load-more-button:active {
  transform: translateY(0px);
  background-color: #3e8e7e;
}

.load-more-button:disabled {
  background-color: #a0d9de;
  cursor: not-allowed;
}

/* Buy Now Button */
.buy-now-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff9900; 
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s;
  text-decoration: none;
}

.buy-now-button:hover {
  background-color: #e68a00;
}

/* Ingredient Button Styles */
.ingredient-button {
  padding: 6px 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
  background-color: #6c757d;
  word-break: break-word;
  font-size: 12px;
}

/* Safety Class Colors */
.safetySafe {
  background-color: #28a745;
}
.safetyLightgreen {
  background-color: #90ee90;
  color: black;
}
.safetyModerate {
  background-color: #ffc107;
  color: black;
}
.safetyWarning {
  background-color: #fd7e14;
}
.safetyUnsafe {
  background-color: #dc3545;
}
.safetyUnknown {
  background-color: #6c757d;
}

/* -----------------------------------------------
   Ingredient Modal
----------------------------------------------- */

.ingredientModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4000;
  animation: fadeIn 0.3s ease-in-out;
}

.ingredientContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

.propertiesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.propertyBubble {
  padding: 4px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.9em;
  display: inline-block;
  white-space: nowrap;
}

/* Boolean & Integer Bubbles */
.booleanBubble {
  background-color: #4caf50;
}
.integerBubble.color0 {
  background-color: green;
}
.integerBubble.color1 {
  background-color: #9ACD32;
}
.integerBubble.color2 {
  background-color: yellow;
  color: black;
}
.integerBubble.color3 {
  background-color: orange;
}
.integerBubble.color4 {
  background-color: red;
}
.integerBubble.colorDefault {
  background-color: gray;
}

.keyContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.keyItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
}
.safetyColorBox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

/* Close Ingredient Modal */
.ingredientCloseModalButton {
  padding: 8px 16px;
  background-color: #0084ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  font-size: 14px;
}

.ingredientCloseModalButton:hover {
  background-color: #0056b3;
}

/* -----------------------------------------------
   Confirmation Modal
----------------------------------------------- */

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2500;
  animation: fadeIn 0.3s ease-in-out;
}

.confirmContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

.confirmButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirmButton {
  background-color: #dc3545; 
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.confirmButton:hover {
  background-color: #a71d2a;
}

.cancelButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cancelButton:hover {
  background-color: #5a6268;
}

/* -----------------------------------------------
   Product Modal
----------------------------------------------- */

.productModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  animation: fadeIn 0.3s ease-in-out;
}

.productContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-in-out;
  overflow-y: auto;
  max-height: 90vh;
}

.modal-product-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.productContent h2 {
  margin-top: 0;
  font-size: 20px;
}

.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #888;
  cursor: pointer;
}

.closeModalButton:hover {
  color: #333;
}

.add-button-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745; /* Green */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button-modal:hover {
  background-color: #218838;
}

.remove-button-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545; /* Red */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button-modal:hover {
  background-color: #c82333;
}

/* -----------------------------------------------
   Traits Section (When Confirming Skin Traits)
----------------------------------------------- */

.traits-section {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.traits-section h2 {
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.traits-sliders {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trait-group {
  display: flex;
  flex-direction: column;
}

.trait-group h3 {
  margin-bottom: 5px;
  color: #555;
}

.confirm-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #218838;
}

.confirm-button:disabled {
  background-color: #a3d7a5;
  cursor: not-allowed;
}

/* -----------------------------------------------
   Markdown Content in Assistant Messages
----------------------------------------------- */

.assistant-message h1,
.assistant-message h2,
.assistant-message h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.assistant-message p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.assistant-message ul,
.assistant-message ol {
  padding-left: 20px;
  margin-bottom: 10px;
}

.assistant-message li {
  margin-bottom: 5px;
}

.assistant-message strong {
  font-weight: bold;
}

.assistant-message em {
  font-style: italic;
}

.assistant-message a {
  color: #7e3ba1;
  text-decoration: none;
}

.assistant-message a:hover {
  text-decoration: underline;
}

/* -----------------------------------------------
   Scrollbar Customization
----------------------------------------------- */

.chat-messages::-webkit-scrollbar,
.product-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.chat-messages::-webkit-scrollbar-track,
.product-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb,
.product-scroll::-webkit-scrollbar-thumb {
  background: #7e3ba1;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-thumb:hover,
.product-scroll::-webkit-scrollbar-thumb:hover {
  background: #8C4D8C;
}

/* -----------------------------------------------
   Animations
----------------------------------------------- */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

/* -----------------------------------------------
   Responsive Design
----------------------------------------------- */

/* Medium Screens */
@media (max-width: 1000px) {
  .chat-container {
    flex-direction: column;
    align-items: center;
  }

  .left-column,
  .right-column {
    width: 90%;
  }

  .chat-messages-container {
    height: 60vh; /* Adjust for smaller screens */
  }

  .product-card,
  .routine-card {
    width: 80px;
    min-height: 120px;
  }

  .product-image,
  .routine-image {
    height: 80px;
  }

  .product-title,
  .routine-title {
    font-size: 8px;
  }

  .buy-now-button,
  .add-button-modal,
  .remove-button-modal {
    padding: 8px 16px;
    font-size: 12px;
  }

  .ingredient-button {
    padding: 4px 10px;
    font-size: 10px;
  }
}

/* Small Screens */
@media (max-width: 600px) {
  .upload-title {
    font-size: 20px;
  }

  .chat-input {
    font-size: 14px;
  }

  .upload-button,
  .end-session-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .traits-section {
    padding: 15px;
  }

  .traits-section h2 {
    font-size: 18px;
  }

  .trait-group h3 {
    font-size: 14px;
  }

  .trait-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .confirm-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .buy-now-button,
  .add-button-modal,
  .remove-button-modal {
    padding: 6px 12px;
    font-size: 10px;
  }

  .ingredient-button {
    padding: 2px 8px;
    font-size: 9px;
  }

  .product-card,
  .routine-card {
    width: 70px;
    min-height: 100px;
  }

  .product-image,
  .routine-image {
    height: 70px;
  }

  .product-title,
  .routine-title {
    font-size: 7px;
  }
}
