/* Container for the entire hero section */
.hero-section-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -100px; /* Preserves the "lower" positioning */
  padding: 0; /* Reset padding if any */
  overflow: hidden; /* Hide overflow for decorative elements */
  background-color: #ffffff;
}

/* Decorative Ellipses (optional) */
.hero-ellipse {
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.3;
  filter: blur(100px);
  z-index: 0;
}

.hero-ellipse-left {
  top: -150px; /* Position above the viewport */
  left: -150px; /* Position to the left of the viewport */
}

.hero-ellipse-right {
  bottom: -150px; /* Position below the viewport */
  right: -150px; /* Position to the right of the viewport */
}

/* Hero content styling */
.hero-content {
  position: relative;
  z-index: 1; /* Ensure content is above decorative elements */
  text-align: center;
  max-width: 900px;
  width: 60%;
  padding: 80px 20px;
  background: linear-gradient(180deg, rgba(48, 16, 85, 1), rgba(48, 16, 85, 1)),
              radial-gradient(circle at top left, rgba(228, 174, 255, 0.3) 20%, rgba(48, 16, 85, 0) 60%),
              radial-gradient(circle at bottom right, rgba(228, 174, 255, 0.3) 20%, rgba(48, 16, 85, 0) 60%);
  background-blend-mode: overlay, normal;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/* Hero Title */
.hero-title {
  font-size: 2.5rem;
  margin: 0 0 20px;
}

/* Hero Subtitle */
.hero-subtitle {
  font-size: 1.2rem;
  margin: 0 0 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Hero Button */
.hero-button {
  background-color: #7e3ba1;
  color: #fff;
  border: none;
  padding: 14px 28px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: #a16fb0;
}

/* Responsive adjustments for screens up to 768px */
@media (max-width: 768px) {
  .hero-section-container {
    margin-bottom: -80px; /* Adjust based on desired overlap */
  }

  .hero-ellipse {
    width: 200px;
    height: 200px;
    filter: blur(80px);
  }

  .hero-ellipse-left {
    top: -100px;
    left: -100px;
  }

  .hero-ellipse-right {
    bottom: -100px;
    right: -100px;
  }

  .hero-content {
    width: 90%; /* Expand content width on smaller screens */
    padding: 60px 15px; /* Adjust padding */
    margin-top: 0; /* Remove negative margin to prevent cutting off text */
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
    max-width: 500px;
  }
}

/* Extra responsive adjustments for screens up to 480px */
@media (max-width: 480px) {
  .hero-section-container {
    margin-bottom: -60px;
    padding: 0 10px;
  }

  .hero-content {
    width: 100%;
    padding: 40px 10px;
    margin-top: 0; /* Ensure no negative margin */
  }

  .hero-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .hero-subtitle {
    font-size: 1rem;
    max-width: 90%;
    margin-bottom: 30px;
  }

  .hero-button {
    padding: 12px 24px;
    font-size: 0.9rem;
  }

  .hero-ellipse {
    width: 150px;
    height: 150px;
    filter: blur(60px);
  }

  .hero-ellipse-left {
    top: -75px;
    left: -75px;
  }

  .hero-ellipse-right {
    bottom: -75px;
    right: -75px;
  }
}
