/* Glow Up Routine Component Styles */

.glow-up-section {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .glow-up-card {
    width: 100%;
    padding: 10px; /* Matches the original padding */
    background-color: #ffffff;
    border: 2px solid #7E3BA1; /* Matches the original border color */
    border-radius: 10px; /* Matches the original border radius */
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
    margin-bottom: 10px; /* Matches the original margin-bottom */
  }
  
  .glow-up-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .glow-up-card h2 {
    font-size: 1.5em;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .glow-up-card p {
    font-size: 1em;
    color: #555555;
    margin-bottom: 30px;
  }
  
  .explore-button {
    padding: 15px 30px;
    background-color: #7E3BA1; /* Replace with your actual primary color */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .explore-button:hover {
    background-color: #8C4D8C; /* Replace with your actual primary hover color */
  }
  
  @media (max-width: 768px) {
    .glow-up-card {
      padding: 10px;
    }
  
    .glow-up-card h2 {
      font-size: 1.2em;
    }
  
    .glow-up-card p {
      font-size: 0.9em;
    }
  
    .explore-button {
      padding: 12px 25px;
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-image-container,
    .responsive-radar-container {
      width: 90% !important;
    }
  
    /* Reorder the right column so that the image appears at the top */
    .responsive-right-column {
      order: -1;
    }
  }